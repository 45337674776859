import React, { useState, useEffect } from "react";
import "./ImageSlider.css";

const slideStyles = {
  width: "60%",
  height: "85%",
  borderRadius: "10px",
  backgroundSize: "cover",
  backgroundPosition: "center",
};

const sliderStyles = {
  position: "relative",
  margin: "2vh",
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
};

const dotsContainerStyles = {
  display: "flex",
  justifyContent: "center",
};

const dotStyle = {
  margin: "0 3px",
  cursor: "pointer",
  fontSize: "20px",
  color: "#808080", // Greyish color
};

const infoContainerStyles = {
  fontFamily: 'Times New Roman',
  fontStyle: 'normal',
  padding: '20px',
};

const ImageSlider = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const interval = setInterval(() => {
      goToNext();
    }, 3000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  const goToNext = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  const slideStylesWidthBackground = {
    ...slideStyles,
    backgroundImage: `url(${slides[currentIndex].url})`,
  };

  const mobileSlideStylesWidthBackground = {
    ...slideStyles,
    width: "92%",
    height: "50vh",
    backgroundImage: `url(${slides[currentIndex].url})`,
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={sliderStyles}>
      <div
        style={
          isMobileView
            ? mobileSlideStylesWidthBackground
            : slideStylesWidthBackground
        }
      ></div>
      <div style={dotsContainerStyles}>
        {slides.map((slide, slideIndex) => (
          <div
            style={{
              ...dotStyle,
              opacity: currentIndex === slideIndex ? 1 : 0.5,
            }}
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
          >
            ●
          </div>
        ))}
      </div>
      {/* Content to be displayed below slider for mobile view */}
      <div className="info-container" style={infoContainerStyles}>
        {/* Add your content here */}
        <h2>Dr.Khushali Bora's Dental Studio</h2>
        <p><h4>Discover comprehensive dental care in Bibwewadi,Pune.Our expert team offers personalized treatments for a healthier, brighter smile.The residents of Bibwewadi and beyond. We are committed to providing exceptional dental care tailored to meet the unique needs of each patient. With a focus on preventive,restorative, and cosmetic dentistry,oral health solutions in a comfortable andwelcoming environment. by delivering personalized dental care of the highest quality. We believe in fostering long-term relationships built on trust, integrity, and professionalism</h4>
        </p>
      </div>
    </div>
  );
};

export default ImageSlider;
