import Services from "../component/Services";
import Testimonials from "./Testimonials";
import SliderPage from "../component/SliderPage";
import CardList from "../component/CardList";
import Review from "../component/Review";

function Index() {
  return (
    <>
      <SliderPage></SliderPage>
      <Services></Services>
      <CardList></CardList>
      <Testimonials></Testimonials>
      <Review></Review>
    </>
  );
}

export default Index;
