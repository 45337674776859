import React from "react";
import "./GeneralDentistry.css";
import img1 from "../../images/Services/ServicesPage/pngegg.png";
import img2 from "../../images/Services/ServicesPage/generaldentistry.jpg";

export default function GeneralDentistry() {
  return (
    <div className="dentistry">
      <div className="posi_tion">
        <center>
          <big>
            <img src={img1} height="150" alt="" />
            <h1 style={{ fontFamily: "Times New Roman", fontSize: "xx-large" }}>
              {" "}
              GENERAL<span> DENTISTRY</span>
            </h1>
          </big>
        </center>
        <br />
        <img className="img_deg" src={img2} alt="Dental Implants Surgery" />
        <h1 style={{ fontFamily: "Times New Roman" }}>General Dentistry Services:</h1>
        <h3 style={{ fontFamily: "Times New Roman"}}>
          <ul>
            <li>
              {" "}
              Dental Examinations: Routine dental check-ups are essential for
              maintaining optimal oral health. During these exams, our
              experienced dentists will assess your teeth, gums, and overall
              oral health, identifying any issues early and developing
              personalized treatment plans.
            </li>
            <li>
              {" "}
              Professional Cleanings: Professional dental cleanings are crucial
              for removing plaque, tartar, and surface stains from your teeth.
              Our skilled hygienists use specialized tools to clean areas that
              may be difficult to reach with regular brushing and flossing,
              helping to prevent cavities and gum disease.
            </li>
            {/* Add other list items */}
          </ul>
        </h3>
        <h3 style={{ fontFamily: "Times New Roman" }}>
          <strong>Benefits of General Dentistry</strong>: Choosing{" "}
          <u>Dr.Khushali Bora's Clinic</u> for your general dentistry needs
          offers numerous benefits, including:
          <ul>
            <li>
              {" "}
              Comprehensive Care: We provide a wide range of general dentistry
              services to meet all of your oral health needs under one roof.
            </li>
            <li>
              Personalized Treatment: Our team takes the time to understand your
              unique dental concerns and develop customized treatment plans
              tailored to your individual needs and goals.
            </li>
            {/* Add other list items */}
          </ul>
        </h3>
      </div>
    </div>
  );
}
