import React from "react";
import "./AboutCard.css";

function AboutCard(props) {
  return (
    <div className="card" style={{ fontFamily: 'Times New Roman', fontStyle: 'normal' }}>
      <div className="card-image">
        <img src={props.image} alt="Card" />
        <div className="card-content">
          <h3 style={{ fontFamily: 'Times New Roman', fontStyle: 'normal' }}>{props.title}</h3>
          <p style={{ fontFamily: 'Times New Roman', fontStyle: 'normal' }}>{props.content}</p>
        </div>
      </div>
      <div className="additional-content" style={{ fontFamily: 'Times New Roman', fontStyle: 'normal' }}>
        {props.additionalContent}
        <a href="/about" style={{ fontFamily: 'Times New Roman', fontStyle: 'normal' }}>Read more</a>
      </div>
    </div>
  );
}

export default AboutCard;
