import React from "react";
import Card from "./AboutCard";
import "./AboutCard.css";
import img1 from "../images/AG96.JPG";

function CardList() {
  return (
    <div className="card-container" style={{ fontFamily: 'Times New Roman', fontStyle: 'normal' }}>
      <h2 style={{ fontFamily: 'Times New Roman', fontStyle: 'normal' }}>About Us</h2>
      <div className="card-list" style={{ fontFamily: 'Times New Roman', fontStyle: 'normal' }}>
        {
          cardsData.map((card, index) => (
            <div key={index} className="card-wrapper">
              <Card
                title={card.title}
                content={card.content}
                image={card.image}
                additionalContent={card.additionalContent}
              />
            </div>
          ))
        }
      </div>
    </div>
  );
}

const cardsData = [
  {
    title: "Dr. Khushali Hitesh Bora",
    content: "Dentist",
    image: img1,
    additionalContent: (
      <div>
        <p>
        Dr. Khushali Bora's Dental Studio, home to Pune's finest dentists, is dedicated to offering top-notch dental care to families throughout Pune. Our approach is centered on treating patients as cherished family members, ensuring an unforgettable experience that fosters enduring relationships. For years, Dr. Khushali Bora's Dental Studio has stood as a beacon of dependable and economical dental care in Bibwewadi, Pune. From toddlers to seniors, our doors are open to everyone. Recognizing that each individual has unique dental needs, our team at Dr. Khushali Bora's Dental Studio is dedicated to understanding and addressing them. With the finest dentists in Bibwewadi leading the way, we're committed to delivering exceptional quality care. Known for our gentle touch and knowledgeable staff, we treat our patients like family, aiming to create an unforgettable experience. Conveniently situated in Bibwewadi, Pune, our clinic boasts cutting-edge technology, ensuring your visit is both comfortable and efficient. At Our Dental Studio, we pledge to provide the care you and your loved ones rightfully deserve.
        </p>
      </div>
    ),
  },
];

export default CardList;
