import React from "react";
import "./PreventiveDentistry.css";
import img1 from "../../images/Services/ServicesPage/pngegg.png";
import img2 from "../../images/Services/ServicesPage/PreventiveD1.jpg";

export default function PreventiveDentistry() {
  return (
    <div className="dentistry" style={{ fontFamily: "Times New Roman" }}>
      <div className="posi_tion">
        <center>
          <big>
            <img src={img1} height="150" alt="" />
            <h1 style={{ fontSize: "xx-large" }}>
              {" "}
              PREVENTIVE <span > DENTISTRY</span>
            </h1>
          </big>
        </center>
        <br />
        <img className="img_deg" src={img2} alt="Dental Implants Surgery" />
        <h1>What is Preventive Dentistry? </h1>
        <h3>
          Preventive dentistry focuses on proactively caring for your teeth and
          gums to prevent dental problems before they occur. This approach
          emphasizes regular dental check-ups, professional cleanings, patient
          education, and early intervention to maintain healthy teeth and gums
          and prevent the need for more extensive dental treatments down the
          line. The Importance of Preventive Dentistry: Preventive dentistry is
          essential for several reasons:
        </h3>
        <br />
        <h3>
          <ul>
            <li>
              Early Detection of Dental Issues: Regular dental check-ups allow
              your dentist to detect and address dental problems early, before
              they progress into more serious and costly conditions.
            </li>
            <li>
              Prevention of Tooth Decay and Gum Disease: By practicing good oral
              hygiene habits and receiving professional cleanings, you can
              reduce the risk of cavities, gum disease, and other oral health
              issues.
            </li>
            <li>
              Preservation of Natural Teeth: Preventive dentistry helps preserve
              your natural teeth, minimizing the need for extractions or
              restorative treatments such as fillings, crowns, or dental
              implants.
            </li>
            <li>
              Improved Overall Health: Oral health is closely linked to overall
              health, with poor oral hygiene being associated with an increased
              risk of systemic conditions such as heart disease, diabetes, and
              respiratory infections. By maintaining good oral health through
              preventive dentistry, you can support your overall well-being.
            </li>
          </ul>
        </h3>
        <h2>
          Preventive Dentistry Services: At <u>Dr.Khushali Bora's Clinic</u>, we
          offer a range of preventive dentistry services to help you maintain
          optimal oral health, including:
        </h2>
        <p>
          <h3>
            <ul>
              <li>
                Dental Examinations: Regular dental check-ups allow your dentist
                to assess your oral health, identify any concerns, and develop
                personalized treatment plans tailored to your needs.
              </li>
              <li>
                Professional Cleanings: Professional dental cleanings remove
                plaque, tartar, and surface stains from your teeth, reducing the
                risk of cavities and gum disease and leaving your smile looking
                and feeling fresh.
              </li>
              <li>
                Dental Sealants: Dental sealants are thin, protective coatings
                applied to the chewing surfaces of the back teeth to prevent
                decay in hard-to-reach areas.
              </li>
              <li>
                Fluoride Treatments: Fluoride treatments help strengthen tooth
                enamel and protect against cavities, particularly in individuals
                at higher risk of tooth decay.
              </li>
              <li>
                Oral Hygiene Education: Our dental team provides valuable oral
                hygiene tips and techniques to help you maintain good oral
                health at home between dental visits.
              </li>
            </ul>
          </h3>
        </p>
      </div>
    </div>
  );
}
