import React from "react";
import "./Orthodontics.css";
import img1 from "../../images/Services/ServicesPage/pngegg.png";
import img2 from "../../images/Services/ServicesPage/ortho.jpg";

export default function Orthodontics() {
  return (
    <div className="dentistry" style={{ fontFamily: "Times New Roman" }}>
      <div className="posi_tion">
        <center>
          <big>
            <img src={img1} height="150" alt="" />
            <h1 style={{ fontSize: "x-large" }}>
              {" "}
              ORTHODONTICS<span >/BRACES</span>
            </h1>
          </big>
        </center>
        <br />
        <img className="img_deg" src={img2} alt="Dental Implants Surgery" />
        <h1>What are Orthodontic Braces? </h1>
        <h3>
          Orthodontic braces are dental appliances used to straighten and align
          teeth, correct bite issues, and improve overall dental health. Braces
          work by applying gentle pressure to gradually move teeth into their
          proper positions over time. This process can address various
          orthodontic issues, including crowded teeth, gaps between teeth,
          misaligned bites, and crooked teeth. Types of Braces: At our dental
          clinic, we offer a variety of braces options to suit your individual
          needs and preferences. These include:
        </h3>
        <br />
        <h3>
          <ul>
            <li>
              Traditional Metal Braces: Traditional metal braces consist of
              metal brackets attached to the front surface of teeth and
              connected by wires. While they are the most noticeable type of
              braces, they are also highly effective for correcting even the
              most complex orthodontic problems.
            </li>
            <li>
              Ceramic Braces: Ceramic braces are similar to traditional metal
              braces but use tooth-colored or clear ceramic brackets that blend
              in with the natural color of teeth, making them less noticeable.
              Ceramic braces are an excellent option for patients who desire a
              more discreet orthodontic treatment.
            </li>
            <li>
              Lingual Braces: Lingual braces are attached to the back surface of
              teeth, making them virtually invisible from the front. This makes
              lingual braces an ideal choice for individuals who wish to
              straighten their teeth discreetly.
            </li>
            <li>
              Invisalign® Clear Aligners: Invisalign® is a popular alternative
              to traditional braces that uses a series of clear, removable
              aligners to gradually straighten teeth. Invisalign® aligners are
              nearly invisible and offer the added convenience of being
              removable for eating, drinking, and oral hygiene.
            </li>
          </ul>
        </h3>
        <h3>
          <strong>Benefits of Orthodontic Braces:</strong> Orthodontic braces offer numerous
          benefits, including:
        </h3>
        <h3>
          <ul>
            <li>
              Improved Smile Aesthetics: Braces can straighten crooked teeth,
              close gaps, and correct bite issues, resulting in a more
              attractive and confident smile.
            </li>
            <li>
              Enhanced Oral Health: Straightening teeth with braces makes them
              easier to clean and maintain, reducing the risk of tooth decay,
              gum disease, and other oral health problems.
            </li>
            <li>
              Better Bite Function: Correcting bite issues with braces can
              improve chewing and speech function, as well as alleviate jaw
              pain and discomfort.
            </li>
            <li>
              Long-Term Dental Stability: Orthodontic treatment with braces
              can help prevent future dental problems by creating a stable and
              properly aligned bite.
            </li>
            <li>
              Boosted Self-Confidence: Achieving a straighter and more
              beautiful smile with braces can boost self-esteem and improve
              overall confidence in social and professional settings
            </li>
          </ul>
        </h3>
      </div>
    </div>
  );
}
