import React from "react";
import "./PainDentistry.css";
import img1 from "../../images/Services/ServicesPage/pngegg.png";
import img2 from "../../images/Services/ServicesPage/PainFree.webp";

export default function PainDentistry() {
  return (
    <div className="dentistry" style={{ fontFamily: "Times New Roman" }}>
      <div className="posi_tion">
        <center>
          <big>
            <img src={img1} height="150" alt="" />
            <h1 style={{ fontSize: "xx-large" }}>
              {" "}
              PAIN-FREE <span >DENTISTRY</span>
            </h1>
          </big>
        </center>
        <br />
        <img className="img_deg" src={img2} alt="Dental Implants Surgery" />
        <h1>PAIN-FREE DENTISTRY?</h1>
        <h3>
          What is pain-free dentistry? 'Pain-free dentistry' or 'comfort
          dentistry' refers to dental procedures performed with minimal
          discomfort to the patient. Many dental clinics offer techniques and
          technologies aimed at reducing pain and anxiety during dental
          treatments. Here are some common strategies employed in pain-free
          dentistry:
        </h3>
        <br />
        <h3>
          <ul>
            <li>
              Local Anesthesia: Dentists often use local anesthesia to numb the
              area they are working on, ensuring that patients don't feel pain
              during procedures such as fillings, root canals, or tooth
              extractions.
            </li>

            <li>
              Topical Anesthetics: Before administering injections, dentists may
              apply a topical numbing gel to the injection site to minimize
              discomfort.
            </li>

            <li>
              Sedation Dentistry: For patients with dental anxiety or those
              undergoing more extensive procedures, sedation dentistry may be
              offered. This involves administering sedatives to help patients
              relax or even sleep through the procedure. Common types of
              sedation include nitrous oxide (laughing gas), oral sedatives, or
              intravenous (IV) sedation.
            </li>

            <li>
              Technological Advances: Modern dental equipment and techniques
              contribute to reducing discomfort during procedures. For example,
              laser dentistry can minimize the need for drills and reduce pain
              and discomfort. Additionally, tools like intraoral cameras allow
              dentists to visualize the mouth more accurately, helping to ensure
              precise and comfortable treatments.
            </li>

            <li>
              Patient Education and Communication: Dentists often take the time
              to explain procedures to patients thoroughly, addressing any
              concerns they may have and providing information on how they can
              minimize discomfort during and after treatment.
            </li>

            <li>
              Comfort Amenities: Some dental clinics provide comfort amenities
              such as soothing music, television screens, or even virtual
              reality headsets to distract patients and create a more relaxed
              environment.
            </li>

            <li>
              Gentle Techniques: Dentists may employ gentle techniques and take
              breaks during procedures to minimize discomfort and allow patients
              to rest if needed.
            </li>
          </ul>
        </h3>
        <h3>
          When looking for a dentist who specializes in pain-free dentistry,
          it's essential to inquire about their approach to patient comfort and
          the specific techniques and technologies they offer. Reading patient
          reviews and asking for recommendations from friends or family members
          can also help you find a dentist who prioritizes a comfortable
          experience for their patients.
        </h3>
      </div>
    </div>
  );
}
