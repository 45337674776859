import React from "react";
import "./StickySocialIcons.css";

const StickySocialIcons = () => {
  return (
    <div className="sticky-social-icons">
      <a href="tel:+919158091199" className="social-icon call">
        <i className="fas fa-phone"></i>
      </a>
      <a href="https://wa.me/+919158091199" className="social-icon whatsapp">
        <i className="fab fa-whatsapp"></i>
      </a>
      <a
        href="https://www.instagram.com/drkhushaliborasdental?igsh=ZmVxYzF5Z3A2dHo1"
        className="social-icon instagram">
        <i className="fab fa-instagram"></i>
      </a>
      <a
        href="https://www.facebook.com/profile.php?id=100087736275335&mibextid=ZbWKwL"
        className="social-icon facebook">
        <i className="fab fa-facebook"></i>
      </a>
    </div>
  );
};

export default StickySocialIcons;
