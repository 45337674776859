import ImageSlider from "./ImageSlider";
    

import img2 from "../images/AG270.JPG";
import img3 from "../images/AG25.JPG";
import img4 from "../images/AG39.JPG";
import img5 from "../images/AG108.JPG";
import img6 from "../images/7e40d0d4-3de2-4eba-9cd2-aec92e4704da.jpeg";
import img7 from "../images/AG150.JPG";
import "./ImageSlider.css";

const SliderPage = () => {
  const slides = [
    
    { url: img2 },
    { url: img3 },
    { url: img4 },
    { url: img5 },
    { url: img6 },

    { url: img7 },
  ];

  const containerStyles = {
    width: "90%",
    height: "500px",
    margin: "0 auto",
  };

  return (
    <div id="bigcontainer">
      <div id="b" style={containerStyles}>
        <ImageSlider slides={slides} />
      </div>
    </div>
  );
};

export default SliderPage;
