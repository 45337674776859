import React from "react";
import "./KidsDentistry.css";
import img1 from "../../images/Services/ServicesPage/pngegg.png";
import img2 from "../../images/Services/ServicesPage/kid1.jpg";

export default function KidsDentistry() {
  return (
    <div className="dentistry" style={{ fontFamily: "Times New Roman" }}>
      <div className="posi_tion">
        <center>
          <big>
            <img src={img1} height="150" alt="" />
            <h1 style={{ fontSize: "xx-large" }}>
              {" "}
              KIDS <span >DENTISTRY</span>
            </h1>
          </big>
        </center>
        <br />
        <img className="img_deg" src={img2} alt="Dental Implants Surgery" />
        <h1>Kids-Friendly Dentistry Services:</h1>
        <h3>
          Kids-Friendly Dentistry Services: We offer a wide range of kids-friendly
          dentistry services to meet the unique needs of children, including:
          <br />
          <ul>
            <li>
              Dental Examinations: Routine dental check-ups allow us to monitor
              children's oral health, detect any early signs of dental problems,
              and provide preventive care as needed.
            </li>
            <br />
            <li>
              Dental Cleanings: Professional dental cleanings help remove
              plaque, tartar, and stains from children's teeth, promoting
              healthy gums and preventing cavities.
            </li>
            <br />
            <li>
              Fluoride Treatments: Fluoride treatments strengthen children's
              tooth enamel and protect against tooth decay, reducing the risk of
              cavities.
            </li>
            <br />
            <li>
              {" "}
              Dental Sealants: Dental sealants are thin, protective coatings
              applied to the chewing surfaces of children's back teeth to
              prevent cavities and decay in hard-to-reach areas.
            </li>
            <br />
            <li>
              Tooth-Colored Fillings: If cavities are detected, we offer
              tooth-colored fillings that blend seamlessly with children's
              natural teeth for discreet and durable restoration.
            </li>
          </ul>
        </h3>
        <br />
        <h3 >
          <strong>Benefits of Kids-Friendly Dentistry:</strong> Choosing kids-friendly dentistry at
          our dental clinic offers numerous benefits for children and their
          families, including:
          <br />
          <ul>
            <li>
              Positive Dental Experiences: Our gentle and supportive approach to
              dental care helps children feel comfortable and confident during
              dental visits, reducing anxiety and fear associated with dental
              treatment.
            </li>
            <li>
              Early Detection and Prevention of Dental Problems: Regular dental
              check-ups allow us to detect and address dental issues early,
              preventing the need for more extensive treatment in the future.
            </li>
            <li>
              Establishment of Good Oral Health Habits: By educating children
              and parents about the importance of oral hygiene and preventive
              care, we help establish lifelong habits that promote good oral
              health.
            </li>
            <li>
              Healthy Smiles for Life: Investing in kids-friendly dentistry sets
              children on the path to a lifetime of healthy smiles, improving
              their overall oral health and well-being. By prioritizing
              preventive care and early intervention, we help our patients
              achieve and maintain healthy smiles for life.
            </li>
          </ul>
        </h3>
      </div>
    </div>
  );
}
