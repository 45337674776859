import React from "react";
import "./Footer.css";

const Footer = () => {
  const googleFormURL =
  "https://docs.google.com/forms/d/e/1FAIpQLSfhOsqlUWm43IjG26QK7KgDCAOOhmIyarXd7AoOkVqM5ov1nQ/viewform?usp=sf_link";

  const handleButtonClick = () => {
    window.open(googleFormURL, "_blank");
  };

  return (
    <div className="footer" style={{ fontFamily: 'Times New Roman', fontStyle: 'normal' }}>
      <div className="top">
        <div className="footer-title">
          <i className="fa-solid fa-tooth"></i>
          <a href="/">DR.KHUSHALI BORA`S DENTAL STUDIO</a>
        </div>
      </div>
      <div className="bottom">
        <div className="bottom-links">
          <h4 style={{ fontFamily: 'Times New Roman', fontStyle: 'normal' }}>Quick Links</h4>
          <a href="/" style={{ fontFamily: 'Times New Roman', fontStyle: 'normal' }}>Home</a>
          <a href="/about" style={{ fontFamily: 'Times New Roman', fontStyle: 'normal' }}>About</a>
          <a href="/servicepage" style={{ fontFamily: 'Times New Roman', fontStyle: 'normal' }}>Services</a>
          <a href="/testimonials" style={{ fontFamily: 'Times New Roman', fontStyle: 'normal' }}>Testimonials</a>
          <a href="/gallery" style={{ fontFamily: 'Times New Roman', fontStyle: 'normal' }}>Gallery</a>
          <a href="/" style={{ fontFamily: 'Times New Roman', fontStyle: 'normal' }} onClick={handleButtonClick}>Book Appointment</a>
        </div>
        <div>
          <h4 style={{ fontFamily: 'Times New Roman', fontStyle: 'normal' }}>Services</h4>
          <div>
            <a href="/teethcleaning" style={{ fontFamily: 'Times New Roman', fontStyle: 'normal' }}>Teeth Cleaning</a>
            <a href="/preventivedentistry" style={{ fontFamily: 'Times New Roman', fontStyle: 'normal' }}>Preventive Dentistry</a>
            <a href="/generaldentistry" style={{ fontFamily: 'Times New Roman', fontStyle: 'normal' }}>General Dentistry</a>
            <a href="/implants" style={{ fontFamily: 'Times New Roman', fontStyle: 'normal' }}>Implants</a>
            <a href="/orthodontics" style={{ fontFamily: 'Times New Roman', fontStyle: 'normal' }}>Orthodontics/Braces</a>
          </div>
        </div>
        <div>
          <h4 style={{ fontFamily: 'Times New Roman', fontStyle: 'normal' }}>-----</h4>
          <div className="footer-services" style={{ fontFamily: 'Times New Roman', fontStyle: 'normal' }}>
            <a href="/smilemakeovers" style={{ fontFamily: 'Times New Roman', fontStyle: 'normal' }}>Smile Makeovers</a>
            <a href="/rootcanal" style={{ fontFamily: 'Times New Roman', fontStyle: 'normal' }}>Root Canal Treatment</a>
            <a href="/paindentistry" style={{ fontFamily: 'Times New Roman', fontStyle: 'normal' }}>TMJ/Pain Dentistry</a>
            <a href="/restorativedentistry" style={{ fontFamily: 'Times New Roman', fontStyle: 'normal' }}>Restorative Dentistry Prosthetics</a>
            <a href="/kidsdentistry " style={{ fontFamily: 'Times New Roman', fontStyle: 'normal' }}>Pediatric/Kids Dentistry</a>
          </div>
        </div>
        <div className="contact-column">
          <h4 style={{ fontFamily: 'Times New Roman', fontStyle: 'normal' }}>Contact Us</h4>
          <p style={{ fontFamily: 'Times New Roman', fontStyle: 'normal' }}>
            Email:<br></br> khushalisdentalstudio@gmail.com<br></br>
            Contact Number:<br></br>+91 9158091199
          </p>
          <iframe
            title="Google Maps Location"
            width="80%"
            height="100px"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3784.0591513581953!2d73.86442307470699!3d18.480979670383345!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2ebfcfa12d209%3A0x9a77d9dd2b3e2e0d!2sDr%20Khushali%20Bora&#39;s%20Dental%20Studio!5e0!3m2!1sen!2sin!4v1710387739843!5m2!1sen!2sin"
          ></iframe>
        </div>
      </div>
      <center style={{ fontFamily: 'Times New Roman', fontStyle: 'normal' }}> 2025 DrKhushali Bora's Dental Studio. All Rights Reserved.
      </center>
      <center style={{ fontFamily: 'Times New Roman', fontStyle: 'normal' }}>Powered By CGPI SOFTWARE PRIVATE LIMITED</center>
    </div>
  );
};

export default Footer;
