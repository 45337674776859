import React from "react";
import "./Implants.css";
import img1 from "../../images/Services/ServicesPage/pngegg.png";
import img2 from "../../images/Services/ServicesPage/implants1.jpg";

export default function Implants() {
  return (
    <div className="dentistry">
      <div className="posi_tion">
        <center>
          <big>
            <img src={img1} height="150" alt="" />
            <h1 style={{ fontFamily: "Times New Roman", fontSize: "xx-large" }}>
              {" "}
              DENTAL <span >IMPLANTS</span>
            </h1>
          </big>
        </center>
        <br />
        <img className="img_deg" src={img2} alt="Dental Implants Surgery" />
        <h1 style={{ fontFamily: "Times New Roman" }}>What are Dental Implants?</h1>
        <h3 style={{ fontFamily: "Times New Roman" }}>
          Dental implants are titanium posts that are surgically placed into the
          jawbone to serve as artificial tooth roots. These implants provide a
          stable foundation for replacement teeth, such as crowns, bridges, or
          dentures, to be attached. Dental implants are designed to mimic the
          function and appearance of natural teeth, offering a long-term
          solution for missing teeth. The Dental Implant Procedure: The dental
          implant procedure typically involves several steps and may require
          collaboration between your dentist and a specialist, such as an oral
          surgeon or periodontist. Here's an overview of the dental implant
          process:<br></br>
          <ul>
            <li>
              Initial Consultation: During the initial consultation, your
              dentist will evaluate your oral health, take X-rays or CT scans,
              and discuss your treatment options based on your specific needs
              and goals.
            </li>
            <li>
              Implant Placement: The dental implant placement involves
              surgically placing the titanium implant into the jawbone. This
              procedure is performed under local anesthesia to ensure your
              comfort.
            </li>
            <li>
              Osseointegration: After implant placement, the jawbone will
              gradually fuse with the implant through a process called
              osseointegration. This integration provides stability and strength
              to the implant, similar to natural tooth roots.
            </li>
            <li>
              Abutment Placement: Once osseointegration is complete, an abutment
              is attached to the implant. The abutment serves as a connector
              between the implant and the replacement tooth or restoration.
            </li>
            <li>
              Restoration: Finally, a custom-made dental crown, bridge, or
              denture is securely attached to the abutment, completing the
              restoration process. The replacement teeth are carefully crafted
              to blend seamlessly with your natural teeth, providing a lifelike
              appearance and function.
            </li>
          </ul>
          <br></br>
          <h3 style={{ fontWeight: "bold" }}>Benefits of Dental Implants:</h3>
          <h3>
            <ul>
              <li>
                Improved Oral Health: Dental implants help preserve jawbone
                density and prevent bone loss, which can occur with untreated
                tooth loss.
              </li>
              <li>
                Enhanced Functionality: With dental implants, you can enjoy the
                ability to eat, speak, and chew comfortably and confidently.
              </li>
              <li>
                Long-Term Solution: Dental implants are durable and can last a
                lifetime with proper care, making them a cost-effective investment
                in your oral health.
              </li>
              <li>
                Natural Look and Feel: The custom-designed replacement teeth
                attached to dental implants closely resemble natural teeth in
                appearance, function, and feel.
              </li>
              <li>
                Enhanced Confidence: Dental implants restore your smile and
                confidence, allowing you to socialize and engage in daily
                activities without worrying about gaps or missing teeth.
              </li>
            </ul>
          </h3>
        </h3>
      </div>
    </div>
  );
}
