import React, { useState } from "react";
import "./Gallery.css";

const Gallery = () => {
  // Import your photos from the images folder
  const photos = [
    require("../images/.jpeg"),
    require("../images/1.JPG"),
    require("../images/2.JPG"),
    require("../images/3.JPG"),
    require("../images/4.JPG"),
    require("../images/5.JPG"),
    require("../images/6.JPG"),
    require("../images/7.JPG"),
    require("../images/8.JPG"),
    require("../images/9.JPG"),
    require("../images/12.JPG"),
    require("../images/10.JPG"),
    require("../images/13.JPG"),
    require("../images/11.JPG"),
    require("../images/14.JPG"),
    require("../images/15.JPG"),
    


    // Add more photo paths as needed
  ];

  // State to manage which photo is being viewed
  const [selectedPhoto, setSelectedPhoto] = useState(null);

  // Function to handle click on a photo
  const handlePhotoClick = (index) => {
    setSelectedPhoto(photos[index]);
  };

  // Function to close the selected photo
  const handleClosePhoto = () => {
    setSelectedPhoto(null);
  };

  return (
    <div className="gallery">
      <h2>Gallery</h2>
      <div className="photos">
        {photos.map((photo, index) => (
          <div key={index} className="photo-container">
            <img
              src={photo}
              alt={`Photo ${index + 1}`}
              onClick={() => handlePhotoClick(index)}
            />
            
          </div>
        ))}
      </div>
      {selectedPhoto && (
        <div className="overlay" onClick={handleClosePhoto}>
          <div className="modal">
            <button className="close-btn" onClick={handleClosePhoto}>
              Close
            </button>
            <img src={selectedPhoto} alt="Selected" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
