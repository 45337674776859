import React from "react";
import "./ServicesCard.css";

function ServicesCard({ title, description, imageSrc, backgroundColor }) {
  return (
    <div className="service-card" style={{ backgroundColor }}>
      <img src={imageSrc} alt={title} />
      <div className="service-info">
        <h4>{title}</h4>
        <p>{description}</p>
        <a href="/" className="explore">
          explore
        </a>
      </div>
    </div>
  );
}

export default ServicesCard;
