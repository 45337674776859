import React from "react";
import "./TeethCleaning.css";
import img1 from "../../images/Services/ServicesPage/pngegg.png";
import img2 from "../../images/Services/ServicesPage/teeth1.jpg";

export default function TeethCleaning() {
  return (
    <div className="dentistry" style={{ fontFamily: "Times New Roman" }}>
      <div className="posi_tion">
        <center>
          <big>
            <img src={img1} height="150" alt="" />
            <h1 style={{ fontSize: "xx-large" }}>
              {" "}
              TEETH<span > CLEANING</span>
            </h1>
          </big>
        </center>
        <br />
        <img className="img_deg" src={img2} alt="Dental Implants Surgery" />
        <h1>Why Professional Teeth Cleaning? </h1>
        <h3>
          Professional teeth cleaning, also known as prophylaxis, is a vital
          part of preventive dental care. Even with diligent brushing and
          flossing at home, plaque and tartar can accumulate on your teeth over
          time, leading to oral health issues such as cavities, gum disease, and
          bad breath. Professional cleanings performed by a dental hygienist or
          dentist are necessary to remove these stubborn deposits and ensure
          your teeth and gums stay healthy.
        </h3>
        <br />
        <h3 >
          <ul>
            <li>
              The Teeth Cleaning Process: During a professional teeth cleaning
              appointment, you can expect the following steps:
              <ul>
                <li>
                  Dental Examination: Before the cleaning begins, your dentist or
                  dental hygienist will conduct a thorough examination of your
                  teeth and gums to assess your oral health and identify any areas
                  of concern.
                </li>
                <li>
                  Plaque and Tartar Removal: Using specialized instruments, the
                  dental professional will carefully remove plaque and tartar
                  buildup from the surfaces of your teeth, including hard-to-reach
                  areas along the gumline and between teeth.
                </li>
                <li>
                  Scaling and Polishing: After plaque and tartar removal, your
                  teeth will be scaled to smooth out rough areas and remove
                  surface stains. This process is often followed by polishing to
                  enhance the appearance of your teeth and remove any remaining
                  plaque or stains.
                </li>
                <li>
                  Fluoride Treatment (Optional): Depending on your oral health
                  needs, your dentist may apply a fluoride treatment to strengthen
                  your tooth enamel and provide additional protection against
                  cavities.
                </li>
                <li>
                  Oral Health Education: Throughout the cleaning appointment, your
                  dentist or dental hygienist will offer personalized oral hygiene
                  tips and recommendations to help you maintain good oral health
                  between visits.
                </li>
              </ul>
            </li>
          </ul>
        </h3>
        <h3>
          <strong>Benefits of Professional Teeth Cleaning:</strong> Professional teeth cleaning
          offers numerous benefits, including:
        </h3>
        <p>
          <h3>
            <ul>
              <li>
                <ul>
                  <li>
                    Prevention of Cavities and Gum Disease: Removing plaque and
                    tartar helps prevent tooth decay and gum disease, reducing the
                    risk of oral health problems.
                  </li>
                  <li>
                    Fresher Breath: Cleaning away bacteria and food particles from
                    your teeth and gums can help eliminate bad breath and promote
                    fresher breath.
                  </li>
                  <li>
                    Brighter Smile: Professional cleaning can remove surface
                    stains and discoloration, leaving your teeth looking brighter
                    and more radiant.
                  </li>
                  <li>
                    Early Detection of Oral Health Issues: Regular dental
                    cleanings allow your dentist to identify and address potential
                    oral health issues early, before they become more serious and
                    costly to treat.
                  </li>
                  <li>
                    Overall Health Benefits: Maintaining good oral health through
                    regular cleanings has been linked to lower risks of certain
                    systemic health conditions, such as heart disease and
                    diabetes.
                  </li>
                </ul>
              </li>
            </ul>
          </h3>
        </p>
      </div>
    </div>
  );
}
