import React from "react";
import "./RootCanal.css";
import img1 from "../../images/Services/ServicesPage/pngegg.png";
import img2 from "../../images/Services/ServicesPage/dental-implants-surgery-concept-pen-tool-created-clipping-path-included-jpeg-easy-composite.jpg";
import img3 from "../../images/Services/ServicesPage/BethesdaFeb2020-min-1024x683.jpg";

function RootCanal() {
  return (
    <div className="dentistry" style={{ fontFamily: "Times New Roman" }}>
      <div className="posi_tion">
        <center>
          <big>
            <img src={img1} height="150" alt="" />
            <h1 style={{ fontSize: "xx-large" }}>
              {" "}
              ROOT <span>CANAL</span>
            </h1>
          </big>
        </center>
        <br />
        <img className="img_deg" src={img2} alt="Dental Implants Surgery" />
        <h1>What is ROOT CANAL?</h1>
        <h3>
          Root canal treatment, also known as endodontic therapy, is a dental
          procedure aimed at treating infection or inflammation within the pulp
          of a tooth. The pulp is the innermost part of the tooth, containing
          blood vessels, nerves, and connective tissue. When the pulp becomes
          infected or inflamed due to decay, trauma, or other factors, it can
          lead to severe pain and dental problems. Why is Root Canal Treatment
          Necessary? Root canal treatment becomes necessary when the pulp inside
          the tooth becomes infected or inflamed. This can happen due to various
          reasons, including:
        </h3>
        <br />
        <h3>
          <ul>
            <li>Severe tooth decay</li>
            <li>Cracked or fractured tooth</li>
            <li>Trauma to the tooth</li>
            <li>Repeated dental procedures on the tooth</li>
            <li>
              Without proper treatment, the infection or inflammation in the
              pulp can spread to the surrounding tissues, leading to abscess
              formation, bone loss, and even systemic health issues.
            </li>
          </ul>
        </h3>
        <img className="img_deg" src={img3} alt="" />
        <h1>The Root Canal Procedure:</h1>
        <h3>
          The Root Canal Procedure: Contrary to popular belief, root canal
          treatment is a relatively straightforward and painless procedure,
          thanks to advancements in dental technology and techniques. Here's
          what you can expect during a typical root canal procedure: <br />
          <ul>
            <li>
              Examination and Diagnosis: Your dentist will examine your tooth
              and may take X-rays to assess the extent of damage to the pulp.{" "}
              <br />
            </li>
            <li>
              Anesthesia: Before starting the procedure, local anesthesia will
              be administered to ensure you're comfortable and pain-free
              throughout the treatment. <br />
            </li>
            <li>
              Accessing the Pulp: Your dentist will create a small opening in
              the crown of the tooth to access the infected or inflamed pulp.{" "}
              <br />
            </li>
            <li>
              Cleaning and Shaping: Using specialized instruments, the dentist
              will remove the diseased pulp tissue, clean the root canals, and
              shape them to prepare for filling. <br />
            </li>
            <li>
              Filling the Canals: Once the root canals are cleaned and shaped,
              they will be filled with a biocompatible material called
              gutta-percha to seal them and prevent reinfection.{" "}
            </li>
            <li>
              Restoration: After filling the canals, a temporary or permanent
              filling will be placed to restore the tooth's structure and
              function.
            </li>
          </ul>
          <br />
        </h3>
        <h3>
          Post-Treatment Care: After a root canal procedure, it's essential to
          follow your dentist's instructions for post-treatment care. This may
          include:
        </h3>
        <h3>
          <ul>
            <li>
              Taking prescribed medications, such as pain relievers or
              antibiotics.
            </li>
            <li>
              Avoiding chewing on the treated tooth until it's fully restored.
            </li>
            <li>
              Maintaining good oral hygiene by brushing and flossing regularly.
            </li>
            <li>
              Scheduling follow-up appointments with your dentist for monitoring
              and further treatment if needed.
            </li>
          </ul>

          
          <h3><strong>Benefits of Root Canal Treatment:</strong></h3>
          <h3>
            <ul>
              <li>Relief from dental pain</li>
              <li>Preservation of the natural tooth</li>
              <li>Restoration of oral health and function</li>
              <li>Prevention of further infection or complications</li>
              <li>Aesthetic improvement of the smile</li>
            </ul>
          </h3>
        </h3>
      </div>
    </div>
  );
}

export default RootCanal;
