import React from "react";
import ServicesCard from "./ServicesCard";
import { Link } from "react-router-dom";
import img1 from "../images/Services/Teethcleaning.png";
import img2 from "../images/Services/Preventivedentistry.png";
import img3 from "../images/Services/generaldentistry.jpeg";
import img4 from "../images/Services/Implants.png";
import img5 from "../images/Services/Orthodontics.png";
import img6 from "../images/Services/smilemakeovers.jpg";
import img7 from "../images/Services/rootcanal.jpeg";
import img8 from "../images/Services/paindentistry.jpeg";
import img9 from "../images/Services/Restrorativedentistry.png";
import img10 from "../images/Services/kidsdentistry.jpeg";

function Services() {
  const linkStyles = {
    fontFamily: 'Times New Roman',
    fontStyle: 'normal',
    textDecoration: 'none', // Ensuring links don't have underline
    color: 'black', // Default link color
  };

  const titleStyles = {
    fontSize: '24px', // Adjust as needed
    fontWeight: 'bold', // Making titles bold
    marginBottom: '0',
    paddingBottom:'10px' // Adding some space between titles and cards
  };

  return (
    <div className="App">
      <h3 fontSize="24px"><b>Dental Clinic Services</b></h3>
      <div className="services-container">
        <Link to={"/teethcleaning"} style={linkStyles}>
          <ServicesCard
            title="Teeth Cleaning"
            imageSrc={img1}
            backgroundColor="#1BCDE3"
            titleStyle={titleStyles} // Pass titleStyles as prop to ServicesCard
          />
        </Link>
        <Link to="/preventivedentistry" style={linkStyles}>
          <ServicesCard
            title="Preventive Dentistry"
            imageSrc={img2}
            backgroundColor="#ffff"
            titleStyle={titleStyles} // Pass titleStyles as prop to ServicesCard
          />
        </Link>
        <Link to="/generaldentistry" style={linkStyles}>
          <ServicesCard
            title="General Dentistry"
            imageSrc={img3}
            backgroundColor="#1BCDE3"
            titleStyle={titleStyles} // Pass titleStyles as prop to ServicesCard
          />
        </Link>
        <Link to="/implants" style={linkStyles}>
          <ServicesCard
            title="Implants"
            imageSrc={img4}
            backgroundColor="#ffff"
            titleStyle={titleStyles} // Pass titleStyles as prop to ServicesCard
          />
        </Link>
        <Link to="/orthodontics" style={linkStyles}>
          <ServicesCard
            title="Orthodontics/Braces"
            imageSrc={img5}
            backgroundColor="#1BCDE3"
            titleStyle={titleStyles} // Pass titleStyles as prop to ServicesCard
          />
        </Link>
        <Link to="/smilemakeovers" style={linkStyles}>
          <ServicesCard
            title="Smile Makeovers"
            imageSrc={img6}
            backgroundColor="#ffff"
            titleStyle={titleStyles} // Pass titleStyles as prop to ServicesCard
          />
        </Link>
        <Link to="/rootcanal" style={linkStyles}>
          <ServicesCard
            title="Root Treatment Canal"
            imageSrc={img7}
            backgroundColor="#1BCDE3"
            titleStyle={titleStyles} // Pass titleStyles as prop to ServicesCard
          />
        </Link>
        <Link to="/paindentistry" style={linkStyles}>
          <ServicesCard
            title="TMJ/Pain Dentistry"
            imageSrc={img8}
            backgroundColor="#ffff"
            titleStyle={titleStyles} // Pass titleStyles as prop to ServicesCard
          />
        </Link>
        <Link to="/restorativedentistry" style={linkStyles}>
          <ServicesCard
            title="Restorative Denitstry"
            imageSrc={img9}
            backgroundColor="#1BCDE3"
            titleStyle={titleStyles} // Pass titleStyles as prop to ServicesCard
          />
        </Link>
        <Link to="/kidsdentistry" style={linkStyles}>
          <ServicesCard
            title="Pediatric/Kids Dentistry"
            imageSrc={img10}
            backgroundColor="#ffff"
            titleStyle={titleStyles} // Pass titleStyles as prop to ServicesCard
          />
        </Link>
      </div>
    </div>
  );
}

export default Services;
