import React, { useState, useEffect } from "react";
import "./Review.css";

const Review = () => {
  const images = [
    require("../images/IMG1.PNG"),
    require("../images/IMG2.PNG"),
    require("../images/IMG3.PNG"),
    require("../images/IMG4.PNG"),
    require("../images/IMG5.PNG"),
    require("../images/IMG6.PNG"),
    require("../images/IMG7.PNG"),
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      nextImage();
    }, 3000); // Change the interval duration as needed (in milliseconds)

    return () => clearInterval(intervalId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <div className="review-container" style={{ fontFamily: "Times New Roman" }}>
      <h2>Check this out!</h2>
      <div className="review-slider">
        <button className="review-icon" onClick={prevImage}>
          &lt;
        </button>
        <div className="review-card">
          <img src={images[currentIndex]} alt="Testimonial" />
        </div>
        <button className="review-icon" onClick={nextImage}>
          &gt;
        </button>
      </div>
    </div>
  );
};

export default Review;


