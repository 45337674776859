import React from "react";
import "./About.css";
import img1 from "../images/About/AG175.JPG";
import img2 from "../images/About/AG66.JPG";
import img3 from "../images/About/AG74.JPG";
import img4 from "../images/About/20240331_194050.png";

function About() {
  const phoneNumber = "9158091199";

  const handleCall = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  return (
    <div className="abt-container" style={{ fontFamily: "Times New Roman" }}>
      <div className="hero-container">
        <div className="hero-content">
          <p id="s1" className="hero-text ice" style={{ fontFamily: "Times New Roman" }}>
            Welcome to Khushali Bora's Dental Studio
          </p>
          <p id="s2" className="hero-text ice shadowed-text" style={{ fontFamily: "Times New Roman" }}>
            Specialized attention. Top-notch treatment.<br /> For
          </p>
          <p id="s3" style={{ fontFamily: "Times New Roman" }}> The best possible results</p>
          <p id="s4" className="hero-text ice" style={{ fontFamily: "Times New Roman" }}>
            Discover compassionate, committed, and personable dental care from
            the experienced team in Bibwewadi, Pune
          </p>
          <p id="s5" className="hero-text ice" style={{ fontFamily: "Times New Roman" }}>
            Book your appointment today!
          </p>
          <button id="btn" onClick={handleCall} style={{ fontFamily: "Times New Roman" }}>
            Call now
          </button>
        </div>
      </div>
      <div className="star">
        <div id="txt">
          <h1 style={{ fontFamily: "Times New Roman" }}>
            Your Reliable Source for Dental Clinic in <br />Bibwewadi, Pune.
          </h1>
          <p id="para1" style={{ fontFamily: "Times New Roman" }}>
           
          Dr. Khushali Bora owns "Dr. Khushali Bora's Dental Studio," a world-class clinic located in Bibwewadi, the heart of Pune. It is a clinic that not only treats you but also gives you an experience. The clinic has a lavish interior to keep your sixth sense absolutely relaxed. Dr. Khushali and her team strive to give patients world-class results using the most advanced technology. She is known for magically creating the best smiles, absolutely painless. She also provides root canal treatment, instant teeth whitening, absolutely invisible braces, and whatnot! Dr. Khushali not only strives very hard to give her patients the best smiles.
         </p>
        </div>
        <div className="hero-image">
          <img src={img1} alt="Example" className="hero-img" />
        </div>
      </div>
      <div className="band">
        <p id="bp" className="band-text" style={{ fontFamily: "Times New Roman" }}>
          Our objective
        </p>
        <span className="band-text " id="bp2" style={{ fontFamily: "Times New Roman" }}>
          Superb Clinical & Quality Care provided in a Nurturing and
          Compassionate Atmosphere<br /> by Pune's Premier Cosmetic Dentist!
        </span>
        <p className="band-text " style={{ fontFamily: "Times New Roman" }}>
          Dr. Khushali Bora's Dental Studio, home to Pune's Finest Dentist, is
          dedicated to offering top-notch dental care to families throughout
          Pune. Our approach is centered on <br />treating patients as
          cherished family members, ensuring an unforgettable experience that
          fosters enduring relationships.
        </p>
        <button id="btn" onClick={handleCall} style={{ fontFamily: "Times New Roman" }}>
          Call now
        </button>
      </div>
      <h3 id="dt" style={{ fontFamily: "Times New Roman" }}>Meet Doctor Khushali</h3>
      <div className="Doctor-info">
        <div className="d-info">
          <p><b>Dr. Khushali Hitesh Bora</b></p>
          <p id="dpara" style={{ fontFamily: "Times New Roman" }}>
            Dr. Khushali Hitesh Bora who has many years of experience as a dental surgeon in the 
            field. Dr. Khushali Bora is a highly acclaimed dentist, renowned for her expertise in cosmetic dentistry and commitment to providing exceptional patient care. With a passion for creating beautiful smiles and a dedication to staying at the forefront of dental technology, Dr. Bora has earned a reputation as a leading dental practitioner in Pune.She currently practices in Bibwewadi, Pune, offering her expertise to patients seeking comprehensive dental care.
          </p>
        </div>

        <div className="doc-image">
          <img src={img4} alt="Example" className="dr-img" />
        </div>
      </div>

      <p id="ot" style={{ fontFamily: "Times New Roman" }}>Meet our Professionals</p>
      <div className="dcards">
        <div id="card1">
          <img src={img2} alt="Example" className="doctor-img" />
          <h2>Dr. Neharika Desarda(BDS)</h2>
          <img src={img3} alt="Example" className="doctor-img" />
          <h2>Dr. Nisha Oswal(BDS)</h2>
        </div>
      </div>
      <div className="container">
        <h1 style={{ fontFamily: "Times New Roman" }}>Our Objective</h1>
        <h3 style={{ fontFamily: "Times New Roman" }}>
          We at Khushali Bora's Dental Studio are committed<br /> to
          delivering the care you and your family rightfully deserve.
        </h3>
        <div className="row">
          <div className="services">
            <i className="fa-solid fa-user-doctor"></i>
            <h2>Experienced Professionals</h2>
            <p style={{ fontFamily: "Times New Roman" }}>Friendly group of doctor which make feel like family</p>
          </div>

          <div className="services">
            <i class="fa-solid fa-house-medical"></i>
            <h2>Magnificent Ambience</h2>
            <p style={{ fontFamily: "Times New Roman" }}>Friendly group of doctor which make feel like family</p>
          </div>

          <div className="services">
            <i class="fa-solid fa-face-smile"></i>
            <h2>Your smile our responsibility</h2>
            <p style={{ fontFamily: "Times New Roman" }}>Friendly group of doctor which make feel like family</p>
          </div>

          <div className="services">
            <i class="fa-solid fa-phone"></i>
            <h2>Convenient Appointment Booking</h2>
            <p style={{ fontFamily: "Times New Roman" }}>Friendly group of doctor which make feel like family</p>
          </div>

          <div className="services">
            <i class="fa-solid fa-tooth"></i>
            <h2>Excellent Dental Makeover</h2>
            <p style={{ fontFamily: "Times New Roman" }}>Friendly group of doctor which make feel like family</p>
          </div>

          <div className="services">
            <i class="fa-solid fa-couch"></i>
            <h2>Comfort like Home</h2>
            <p style={{ fontFamily: "Times New Roman" }}>Friendly group of doctor which make feel like family</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
