import React from "react";
import "./SmileMakeovers.css";
import img1 from "../../images/Services/ServicesPage/pngegg.png";
import img2 from "../../images/Services/ServicesPage/SmileMakeOvers2.jpg";

export default function SmileMakeovers() {
  return (
    <div className="dentistry">
      <div className="posi_tion">
        <center>
          <big>
            <img src={img1} height="150" alt="" />
            <h1 style={{ fontFamily: "Times New Roman", fontSize: "xx-large" }}>
              {" "}
              SMILE <span >MAKEOVER</span>
            </h1>
          </big>
        </center>
        <br />
        <img className="img_deg" src={img2} alt="Dental Implants Surgery" />
        <h1 style={{ fontFamily: "Times New Roman" }}>What is a Smile Makeover? </h1>
        <h3 style={{ fontFamily: "Times New Roman" }}>
          A smile makeover is a customized treatment plan designed to improve
          the appearance of your smile through a combination of cosmetic dental
          procedures. This comprehensive approach allows our experienced
          cosmetic dentists to address multiple aesthetic concerns
          simultaneously, such as tooth discoloration, misalignment, chipped or
          cracked teeth, gaps between teeth, and more. Smile Makeover
          Procedures: At <u>Dr.Khushali Bora's Dental Clinic</u>, we offer a
          wide range of cosmetic dentistry procedures as part of our smile
          makeover treatments, including:
        </h3>
        <br />
        <h3 style={{ fontFamily: "Times New Roman" }}>
          <ul>
            <li>
              Teeth Whitening: Professional teeth whitening can brighten and
              rejuvenate your smile by removing stains and discoloration caused
              by aging, dietary habits, and lifestyle factors.
            </li>
            <li>
              Porcelain Veneers: Porcelain veneers are thin shells of porcelain
              that are bonded to the front surfaces of teeth to improve their
              appearance, covering imperfections such as chips, cracks, and
              stains.
            </li>
            <li>
              Dental Crowns: Dental crowns are custom-made restorations that
              cover and protect damaged or weakened teeth, restoring their
              strength, function, and appearance.
            </li>
            <li>
              Dental Bonding: Dental bonding involves applying a tooth-colored
              composite resin to the surface of teeth to repair minor
              imperfections such as chips, cracks, and gaps.
            </li>
            <li>
              Orthodontic Treatments: Orthodontic treatments such as braces or
              clear aligners can straighten crooked teeth, correct bite issues,
              and improve overall smile aesthetics.
            </li>
            <li>
              Gum Contouring: Gum contouring, also known as gum reshaping, can
              improve the appearance of a gummy smile or uneven gum line by
              removing excess gum tissue and reshaping the gums for a more
              balanced smile.
            </li>
            <li>
              Dental Implants: Dental implants are a durable and long-lasting
              solution for replacing missing teeth, restoring your smile's
              appearance, function, and confidence.
            </li>
          </ul>

          <br />
        </h3>
        <h3 style={{ fontFamily: "Times New Roman" }}>
          <strong>Benefits of Smile Makeovers:</strong> Smile makeovers offer numerous benefits,
          including:
        </h3>
        <p>
          <h3 style={{ fontFamily: "Times New Roman" }}>
            <ul>
              <li>
                Enhanced Smile Aesthetics: Smile makeovers can address a wide
                range of cosmetic concerns to create a more symmetrical,
                balanced, and attractive smile.
              </li>
              <li>
                Improved Self-Confidence: Achieving a beautiful smile with a
                smile makeover can boost self-esteem and improve confidence in
                social and professional settings.
              </li>
            </ul>
          </h3>
        </p>
      </div>
    </div>
  );
}
