import React, { useState } from "react";
import "./Navbar.css";
import logo from "../images/logo.png";

const Navbar = () => {
  const [click, setClick] = useState(false);
  const [servicesDropdown, setServicesDropdown] = useState(false);

  const handleClick = () => setClick(!click);
  const toggleServicesDropdown = () => setServicesDropdown(!servicesDropdown);

  const googleFormURL =
    "https://docs.google.com/forms/d/e/1FAIpQLSfhOsqlUWm43IjG26QK7KgDCAOOhmIyarXd7AoOkVqM5ov1nQ/viewform?usp=sf_link";

  const handleButtonClick = () => {
    window.open(googleFormURL, "_blank");
  };

  const linkStyles = {
    fontFamily: 'Times New Roman',
    fontStyle: 'normal',
  };

  return (
    <nav className="navbar" style={{ fontFamily: 'Times New Roman', fontStyle: 'normal' }}>
      <div className="logo-container">
        <img src={logo} alt="" className="logo"></img>
      </div>
      <div className={click ? "mobile-menu" : "menu-links"}>
        <a href="/" style={linkStyles}>Home</a>
        <div className="dropdown-wrapper">
          <span className="services-link" onClick={toggleServicesDropdown} style={linkStyles}>
            Services
            <i class="fa-solid fa-circle-chevron-down"></i>
          </span>
          {servicesDropdown && (
            <div className="dropdown">
              <div className="dropdown-column">
                <div className="dropdown-content">
                  <a href="/rootcanal" style={linkStyles}>Root Canal</a>
                  <a href="/teethcleaning" style={linkStyles}>Teeth Cleaning</a>
                  <a href="/preventivedentistry" style={linkStyles}>Preventive Denitstry</a>
                  <a href="/generaldentistry" style={linkStyles}>General Denitstry</a>
                  <a href="/implants" style={linkStyles}>Implants</a>
                </div>
              </div>
              <div className="dropdown-column">
                <div className="dropdown-content">
                  <a href="/orthodontics" style={linkStyles}>Orthodontics</a>
                  <a href="/smilemakeovers" style={linkStyles}>Smile Makeovers</a>
                  <a href="/paindentistry" style={linkStyles}>Pain Denitstry</a>
                  <a href="/restorativedentistry" style={linkStyles}>Restorative Denitstry</a>
                  <a href="/kidsdentistry" style={linkStyles}>Kids Denitstry</a>
                </div>
              </div>
            </div>
          )}
        </div>
        <a href="/about" style={linkStyles}>About</a>
        <a href="/testimonials" style={linkStyles}>Testimonials</a>
        <a href="/gallery" style={linkStyles}>Gallery</a>
      </div>
      <button className="book-appointment" onClick={handleButtonClick} style={linkStyles}>
        Book Appointment
      </button>
      <div className="menu-btn" onClick={handleClick}>
        <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
      </div>
    </nav>
  );
};

export default Navbar;
