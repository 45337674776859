import React from "react";
import "./RestorativeDenistry.css";
import img1 from "../../images/Services/ServicesPage/pngegg.png";
import img2 from "../../images/Services/ServicesPage/Restorative-Treatment.jpeg.jpg";

export default function RestorativeDenistry() {
  return (
    <div className="dentistry" style={{ fontFamily: "Times New Roman" }}>
      <div className="posi_tion">
        <center>
          <big>
            <img src={img1} height="150" alt="" />
            <h1 style={{ fontSize: "xx-large" }}>
              {" "}
              RESTORATIVE <span >DENTISTRY</span>
            </h1>
          </big>
        </center>
        <br />
        <img className="img_deg" src={img2} alt="Dental Implants Surgery" />
        <h1>Restorative Dentistry Services</h1>
        <h3>
          Restorative Dentistry Services: At our dental clinic, we offer a
          comprehensive range of restorative dentistry services to repair,
          strengthen, and enhance your natural teeth, including:
          <ul>
            <li>
              Dental Fillings: Our tooth-colored composite fillings are used to
              repair cavities and restore the strength and function of damaged
              teeth while blending seamlessly with your natural tooth color.
            </li>
            <li>
              Dental Crowns: Dental crowns, or caps, are custom-made
              restorations that cover and protect damaged or weakened teeth,
              restoring their appearance, strength, and function.
            </li>
            <li>
              Dental Bridges: Dental bridges are used to replace one or more
              missing teeth by anchoring artificial teeth (pontics) to adjacent
              natural teeth or dental implants, restoring your smile's
              appearance and function.
            </li>
            <li>
              Dental Implants: Dental implants are a durable and long-lasting
              solution for replacing missing teeth. These titanium posts are
              surgically implanted into the jawbone and topped with custom-made
              crowns, bridges, or dentures for a natural-looking and functional
              restoration.
            </li>
            <li>
              Dentures: Dentures are removable prosthetic devices used to
              replace multiple missing teeth. We offer full dentures for
              patients missing all of their teeth and partial dentures for those
              missing some teeth while preserving remaining natural teeth.
            </li>
          </ul>
          <br />
          Prosthetic Solutions: In addition to restorative dentistry services,
          we offer a range of prosthetic solutions to address more extensive
          tooth loss and oral health concerns, including:
          <ul>
            <li>
              Implant-Supported Dentures: Implant-supported dentures are
              dentures that are anchored to dental implants for added stability
              and support, preventing them from slipping or shifting while
              eating, speaking, or smiling.
            </li>
            <li>
              All-on-4® Treatment Concept: The All-on-4® treatment concept
              involves strategically placing four dental implants to support a
              full arch of fixed replacement teeth, providing a permanent and
              natural-looking solution for patients with extensive tooth loss.
            </li>
            <li>
              Overdentures: Overdentures are removable dentures that are secured
              in place by dental implants or natural tooth roots, offering
              improved stability and function compared to traditional removable
              dentures.
            </li>
          </ul>
        </h3>
        <br />
        <h3>
          <strong>Benefits of Restorative Dentistry and Prosthetic Solutions:</strong> Choosing
          restorative dentistry and prosthetic solutions at our dental clinic
          offers numerous benefits, including:
          <ul>
            <li>
              Improved Oral Function: Restorative treatments and prosthetic
              solutions can restore your ability to chew, speak, and smile with
              confidence, enhancing your overall oral function and quality of
              life.
            </li>
            <li>
              Enhanced Aesthetics: Restoring missing or damaged teeth can
              improve the appearance of your smile and facial aesthetics,
              boosting your self-confidence and self-esteem.
            </li>
            <li>
              Preserved Oral Health: Restorative dentistry helps preserve the
              health and integrity of your natural teeth, preventing further
              decay, damage, or tooth loss.
            </li>
            <li>
              Long-Term Durability: Our high-quality restorations and prosthetic
              devices are designed to be durable and long-lasting, providing
              reliable function and aesthetics for years to come.
            </li>
            <li>
              Personalized Care: Our experienced dental team takes the time to
              understand your unique needs and goals, developing personalized
              treatment plans tailored to your individual preferences and
              budget.
            </li>
          </ul>
        </h3>
      </div>
    </div>
  );
}
