import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./component/Navbar";
import Footer from "./component/Footer";
import Index from "./routes/Index";
import About from "./routes/About";
import Testimonials from "./routes/Testimonials";
import StickySocialIcons from "./component/StickySocialIcons";
import RootCanal from "./routes/Services/RootCanal";
import GeneralDentistry from "./routes/Services/GeneralDentistry";
import Implants from "./routes/Services/Implants";
import Orthodontics from "./routes/Services/Orthodontics";
import SmileMakeovers from "./routes/Services/SmileMakeovers";
import RestorativeDenistry from "./routes/Services/RestorativeDenistry";
import TeethCleaning from "./routes/Services/TeethCleaning";
import PreventiveDentistry from "./routes/Services/PreventiveDentistry";
import PainDentistry from "./routes/Services/PainDentistry";
import KidsDentistry from "./routes/Services/KidsDentistry";
import Gallery from "./routes/Gallery";

function App() {
  return (
    <Router>
      <div className="App">
        <div className="blur-container"></div> {/* Add this line */}
        <Navbar />
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/about" element={<About />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/rootcanal" element={<RootCanal />} />
          <Route path="/teethcleaning" element={<TeethCleaning />} />
          <Route path="/preventivedentistry" element={<PreventiveDentistry />} />
          <Route path="/generaldentistry" element={<GeneralDentistry />} />
          <Route path="/implants" element={<Implants />} />
          <Route path="/orthodontics" element={<Orthodontics />} />
          <Route path="/smilemakeovers" element={<SmileMakeovers />} />
          <Route path="/paindentistry" element={<PainDentistry />} />
          <Route path="/restorativedentistry" element={<RestorativeDenistry />} />
          <Route path="/kidsdentistry" element={<KidsDentistry />} />
          <Route path="/gallery" element={<Gallery />} />
        </Routes>
        <StickySocialIcons />
        <Footer />
      </div>
    </Router>
    
  );
}

export default App;
