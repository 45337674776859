import React, { useState } from "react";
import "./Testimonials.css";
// import rimg1 from "../images/rimg1.jpg";

const Testimonials = () => {
  const testimonials = [
    {
      quote:
        "I got my dental work done with an expert like Dr. Khushali. I didn't felt the injection, she took care that I felt minimum pain and had a successful extraction of my wisdom tooth. The equipments used are the latest one. I'm happy.",
      author: "Akash Bhandari",
    },
    {
      quote:
        "The best dental care I have ever experienced! Professional ,courteous, and friendly staff and clinic is very hygienic. Dr.Khushali is a wonderful dentist. She explains the procedure in details and gives her honest advice.She explained me in detail about the treatment. While doing the treatment I didn't feel any pain and it was the best treatment. I am very happy and satisfied. Would highly recommend to anyone!!",
      author: "Poonam Jinde",
    },
    {
      quote:
        "Dr. Khushali did awesome job cleaning my teeth. She explained everything in detail, gave honest advice and made sure I was comfortable.The staff is also very Professional, courteous, and friendly.",
      author: "Mamta Surana",
    },
    {
      quote:
        "Had very pleasant experience with Dr khushali. She treated my 4 yr old child very patiently and nicely. treated with minimal pain. Overall good experience. Soft spoken and clean and beautiful dental studio",
      author: "Shubhada Lalwani",
    },
    {
      quote:
        "Dr. Khushali bora is very punctual. She takes patients on appointment basis only. She tells you the actual facts, accordingly she treats you. She removed my wisdom tooth. It was paining badly. She is very cooperative.Her clinic is just wow..... !Must visit her studio.",
      author: "Nisha Shah",
    },
    {
      quote:
        "Dr Khushi Mam's treatment is always amazing,  very quality of customer service and fantastic results. One of the only place in that is a seriously highly recommended and takes very good care of you.",
      author: "Vidya aheri",
    },
    {
      quote:
        "Dr. Khushali is really good & clean in her work. Very much polite & she makes sure to calm & talk to her patients.",
      author: "Akash Shaw",
    },
    {
      quote:
        "Thank you for your exceptional care and dedication for the dental treatment. Your friendly approach made each visit a comfortable and positive experience. Grateful for having you as my dentist. Results are just flawless 👏",
      author: "Dhanashree Oswal",
    },
    {
      quote:
        "I had taken treatment for removal of wisdom teeth and it was really very painless. Thanks to Dr.Khushali.",
      author: "Gayatri Garad",
    },
    {
      quote:
        "A very gentle , accurate and speedy treatment.I got treated thrice under Dr. Khushsli and everytiime  it is same experience.  Total satisfaction. Thanks doctor.",
      author: "Vivek Satpute",
    },
    {
      quote:
        "Excellent work by Mrs Bora.The take care of their patients and take regular follow ups Will certainly recommend this clinic for any dental issues.",
      author: "HONESTTLLY INSANE",
    },
    {
      quote:
        "Dr Khushi Mam's treatment is always amazing,  very quality of customer service and fantastic results. One of the only place in that is a seriously highly recommended and takes very good care of you.",
      author: "Vidya aheri",
    },
    {
      quote:
        "Dr. Khushali's Dental World is very nice and pleasant. Madam is very professional and intelligent. They removed my molars and installed dentures. Perfect job.I will always recommend his clinic to all my friends and relatives.My suggestion is about charges.Dr Khushali's Dental service charges seemed a bit higher side in terms of treatment. Mukund texture.....September 2023",
      author: "Mukund potdar",
    },
    // {
       
    //   image: <img src={rimg1} alt="Example" className="dr-img" />
    // },
    // Add more testimonials as needed
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevTestimonial = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="testimonials-container" style={{ fontFamily: "Times New Roman" }}>
      <h2>Testimonials</h2>
      <h1>What Our Clients Says</h1>
      <div className="testimonials-slider">
        <button className="slider-icon" onClick={prevTestimonial}>
          <i className="fa-solid fa-caret-left"></i>
        </button>
        <div className="testimonial-card">
          <p className="quote">{testimonials[currentIndex].quote}</p>
          <div className="author">
            <i className="fa-regular fa-circle-user"></i>
            <span>{testimonials[currentIndex].author}</span>
          </div>
        </div>
        <button className="slider-icon" onClick={nextTestimonial}>
          <i className="fa-solid fa-caret-right"></i>
        </button>
      </div>
    </div>
  );
};

export default Testimonials;
